import { Button, Divider, Typography } from "antd";
import { Form } from "react-router";

import { AppleIcon, GoogleIcon } from "./ProviderIcons";

interface Props {
  loading: boolean;
}

const Text = Typography.Text;

const ExtraLoginOptions = ({ loading }: Props) => {
  return (
    <section className="flex flex-col w-full">
      <div className="w-full relative flex items-center justify-center">
        <Divider className="w-full" />
        <Text className="absolute " type="secondary" style={{ zIndex: 1 }}>
          OR
        </Text>
      </div>

      <div className="pt-3 pb-2 w-full">
        <Form method="post" action="/login?provider=google&action=signin">
          <Button size="large" className="w-full" icon={<GoogleIcon />} htmlType="submit" disabled={loading}>
            Sign in with Google
          </Button>
        </Form>
      </div>
      <div className="pt-2 pb-3 w-full">
        <Form method="post" action="/login?provider=apple&action=signin">
          <Button size="large" className="w-full" icon={<AppleIcon />} htmlType="submit" disabled={loading}>
            Sign in with Apple
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default ExtraLoginOptions;
