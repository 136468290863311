import { Space } from "antd";
import { Typography } from "antd";
import ForgotPasswordForm from "./forms/forgotPassword";
import LoginForm from "./forms/login";
import VerifyEmailForm from "./forms/verifyEmail";
import { authState } from "./state";
import { Button } from "antd";
const DynamicAuthForm = () => {
  const screen = authState((state) => state.screen);

  switch (screen) {
    case "login":
      return <LoginForm />;
    case "verifyEmail":
      return <VerifyEmailForm />;
    case "forgotPassword":
      return <ForgotPasswordForm />;
    default:
      return <MissingScreen />;
  }
};

const MissingScreen = () => {
  return (
    <div>
      <Space direction="vertical" size="large" className="w-full">
        <Typography.Text style={{ fontSize: "1.1rem" }}>Something went wrong! Please try again.</Typography.Text>
        <Button
          onClick={() => authState.getState().setScreen("login")}
          type="primary"
          size="large"
          htmlType="submit"
          className="w-full"
        >
          Back to sign in
        </Button>
      </Space>
    </div>
  );
};

export default DynamicAuthForm;
