import Spinner from "@/components/Loading/PageLoading";
import { useAuthenticator } from "@/hooks/useAuth";
import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { LogoWithText } from "./LogoWithText";

const AuthBase = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator();
  const isAuthenticated = authStatus === "authenticated";

  return (
    <AnimatePresence>
      {isAuthenticated ? (
        <motion.div
          key="spinner"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="w-screen h-screen flex items-center justify-center"
        >
          <Spinner />
        </motion.div>
      ) : (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Flex
            minH={{ base: "auto", md: "100vh" }}
            bgGradient={{
              md: "linear(to-r, bg.accent.default 50%, bg.surface 50%)",
            }}
          >
            <Row className="w-full">
              <Col xs={24} md={12}>
                <Box className="w-full h-full" bg={"#000000"}>
                  <Center flex="1" px={4} className="h-full">
                    <Row className="text-white z-1">
                      <Col xs={24}>
                        <Row justify="center">
                          <LogoWithText />
                        </Row>
                      </Col>
                      <Col xs={0} md={24}>
                        <Center>
                          <Heading size={"md"}>Solutions for Electricians, By Electricians</Heading>
                        </Center>
                      </Col>
                    </Row>
                  </Center>
                </Box>
              </Col>
              <Col xs={24} md={12}>
                <Box className="w-full h-full pt-7">
                  <Center flex="1" px={4} className="w-full h-full">
                    {children}
                  </Center>
                </Box>
              </Col>
            </Row>
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AuthBase;
