import { useYupValidationResolver } from "@/utils";
import { useFetcher, useNavigation, useSearchParams } from "react-router";
import { authState } from "../../state";

import { checkIfEmailExists, registerUser, sendEmailVerificationCode } from "@/apiCalls/user/usersCRUD";
import { Button, Input, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { CloseCircleOutlined } from "@ant-design/icons";
import ExtraLoginOptions from "../../components/ExtraLoginOptions";

type authForm = {
  email: string;
  password: string;
  confirmPassword?: string;
};

const Text = Typography.Text;

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const [emailCheckLoading, setEmailCheckLoading] = useState<boolean>(false);
  const [userEmailExists, setUserEmailExists] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(searchParams.get("error") ?? "");

  const fetcher = useFetcher();
  const [userIsViewingSharedProject, setUserIsViewingSharedProject] = useState(false);
  const navigation = useNavigation();
  const navigating = navigation.state === "submitting" || navigation.state === "loading";

  useEffect(() => {
    const checkIfUserIsViewingSharedProject = async () => {
      const redirectUrl = document.cookie
        .split("; ")
        .find((row) => row.startsWith("redirectUrl="))
        ?.split("=")[1];
      if (redirectUrl?.includes("/share")) {
        setUserIsViewingSharedProject(true);
      }
    };

    checkIfUserIsViewingSharedProject();
  }, []);

  const email = authState((state) => state.email);

  const initalValues: authForm = {
    email: email,
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required("email is required"),
    password: userEmailExists === null ? yup.string() : yup.string().required("password is required"),
    confirmPassword:
      userEmailExists === false
        ? yup
            .string()
            .required("password confirmation is required")
            .test("passwords-match", "Passwords must match", function (value) {
              return value === this.parent.password;
            })
        : yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<authForm>({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: initalValues,
    reValidateMode: "onBlur",
    shouldUseNativeValidation: false,
    mode: "onBlur",
  });

  const emailWatcher = watch("email");
  const passwordWatcher = watch("password");
  const confirmPasswordWatcher = watch("confirmPassword");

  useEffect(() => {
    authState.getState().setEmail(emailWatcher || "");
    authState.getState().setPassword(passwordWatcher || "");
    authState.getState().setConfirmPassword(confirmPasswordWatcher || "");
  }, [emailWatcher, passwordWatcher, confirmPasswordWatcher]);

  useEffect(() => {
    setUserEmailExists(null);
  }, [email]);

  useEffect(() => {
    console.log(fetcher.data);
    if (fetcher.data?.error) {
      setError(fetcher.data.error);
    }
  }, [fetcher.data]);

  const login = async ({ email, password }: authForm) => {
    //looking for the redirect? Look at the action for /login
    await fetcher.submit(
      {
        email: email,
        password: password,
      },
      {
        method: "post",
        action: "/login?provider=cognito&action=signin",
      },
    );
  };

  const sendEmailVerification = async () => {
    try {
      setLoading(true);
      await sendEmailVerificationCode(email);
      authState.getState().setScreen("verifyEmail");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (values: authForm) => {
    try {
      setLoading(true);
      await registerUser(values.email, values.password, values.confirmPassword);
      await login({ email: values.email, password: values.password });
    } catch (error) {
      console.log("sign up error", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignInError = async (error: string) => {
    if (error.includes("User is not confirmed")) {
      await sendEmailVerification();
    } else {
      setError(error);
    }
  };

  const clearEmail = () => {
    setValue("email", "");
    setValue("password", "");
    setValue("confirmPassword", "");
    setError("");
    setTimeout(() => {
      document.getElementById("email-input")?.focus();
    }, 100);
  };

  const onFormSubmit = async (formValues) => {
    if (userEmailExists === null) {
      await checkEmailExists();
      setTimeout(() => {
        document.getElementById("password-input")?.focus();
      }, 100);
      return;
    } else {
      userEmailExists === false ? signUp(formValues) : login(formValues);
    }
  };

  const checkEmailExists = async () => {
    setEmailCheckLoading(true);
    const emailExists = await checkIfEmailExists(email);

    setUserEmailExists(emailExists);
    setEmailCheckLoading(false);

    return emailExists;
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} data-testid="login-form">
      <Space direction="vertical" size="middle" className="w-full">
        {userIsViewingSharedProject && (
          <>
            <Row>This project requires you to log in before viewing it.</Row>
            <Row data-testid="shared-project-message">
              Please log in or sign up to view the project that was shared with you.
            </Row>
          </>
        )}
        <Row>
          <Text strong>Email</Text>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <Input
                  size="large"
                  autoFocus
                  onChange={onChange}
                  value={value}
                  placeholder="email@email.com"
                  type="email"
                  disabled={userEmailExists !== null}
                  style={{ paddingRight: 40 }}
                  id="email-input"
                  data-testid="email-input"
                  autoCorrect="off"
                  autoCapitalize="off"
                />
                <button
                  type="button"
                  onClick={clearEmail}
                  style={{
                    color: "gray",
                    visibility: email ? "visible" : "hidden",
                    position: "absolute",
                    top: 53,
                    right: 35,
                    fontSize: 18,
                  }}
                >
                  <CloseCircleOutlined />
                </button>
              </>
            )}
          />
          <Text type="danger">{errors.email && errors.email?.message}</Text>
        </Row>
        <div
          style={{
            overflow: "hidden",
            transition: "max-height 1s ease-in-out, opacity 1s ease-in-out",
            opacity: userEmailExists === null ? 0 : 1,
            maxHeight: userEmailExists === null ? 0 : 1000,
          }}
        >
          <PasswordFields
            userEmailExists={userEmailExists}
            control={control}
            email={email}
            errors={errors}
            error={error}
          />
        </div>
      </Space>

      <LoginButton
        userEmailExists={userEmailExists}
        loading={loading || navigating}
        emailCheckLoading={emailCheckLoading}
      />

      <ExtraLoginOptions loading={loading || navigating} />
    </form>
  );
};

const PasswordFields = ({ userEmailExists, email, control, errors, error }) => {
  // Email check has not been done yet - hide password fields
  if (email === "") return null;

  if (userEmailExists === false) {
    return <NewPasswordFields userEmailExists={userEmailExists} control={control} errors={errors} error={error} />;
  } else {
    return (
      <ExistingPasswordFields
        userEmailExists={userEmailExists}
        email={email}
        control={control}
        errors={errors}
        error={error}
      />
    );
  }
};

const ExistingPasswordFields = ({ userEmailExists, control, errors, error, email }) => {
  return (
    <div style={{ visibility: userEmailExists ? "visible" : "hidden" }}>
      <div style={{ marginBottom: 20, textAlign: "center", fontSize: "1.1rem" }}>Enter your password to continue:</div>
      <Row>
        <Text strong>Password</Text>
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input.Password
              id="password-input"
              data-testid="password-input"
              minLength={8}
              size="large"
              onChange={onChange}
              value={value}
              placeholder="password"
            />
          )}
        />

        <Text
          style={{ cursor: "pointer" }}
          onClick={() => {
            authState.getState().setScreen("forgotPassword");
          }}
        >
          Forgot Password?
        </Text>
      </Row>
      <Row>
        <Text type="danger">{errors.password && errors.password?.message}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Row>
    </div>
  );
};

const NewPasswordFields = ({ userEmailExists, control, errors, error }) => {
  return (
    <div style={{ visibility: userEmailExists ? "hidden" : "visible" }}>
      <div style={{ marginBottom: 20, textAlign: "center", fontSize: "1.1rem" }}>Setup your password to continue:</div>
      <Row>
        <Text strong>Password</Text>
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input.Password
              id="password-input"
              data-testid="password-input"
              minLength={8}
              size="large"
              onChange={onChange}
              value={value}
              placeholder="password"
            />
          )}
        />
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Text strong>Confirm Password</Text>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input.Password
              minLength={8}
              data-testid="confirm-password-input"
              size="large"
              onChange={onChange}
              value={value}
              placeholder="confirm password"
            />
          )}
        />
      </Row>
      <Row>
        <Text type="danger">{errors.confirmPassword && errors.confirmPassword?.message}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Row>
    </div>
  );
};

const LoginButton = ({ userEmailExists, loading, emailCheckLoading }) => {
  const loginButtonText = userEmailExists === null ? "Continue" : "Sign in";

  return (
    <section className="flex flex-col w-full mt-2">
      <div className="pt-3 pb-3 w-full">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading || emailCheckLoading}
          disabled={loading || emailCheckLoading}
          className="w-full"
          data-testid="login-button"
        >
          {loginButtonText}
        </Button>
      </div>
    </section>
  );
};

export default LoginForm;
