import { sendForgotPasswordCode, verifyForgotPasswordCode } from "@/apiCalls/user/usersCRUD";
import { Button, Flex, Input, Space, Spin, Typography } from "antd";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useActionData, useSubmit } from "react-router";
import { authState } from "../../state";

const Text = Typography.Text;

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const actionData = useActionData();
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const submit = useSubmit();
  const email = authState((state) => state.email);
  const goToLogin = () => {
    authState.getState().setScreen("login");
  };

  console.log("actionData", actionData);

  const triggerForgotPasswordEmail = useCallback(async () => {
    try {
      setLoading(true);
      await sendForgotPasswordCode(email);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [email]);

  useEffect(() => {
    triggerForgotPasswordEmail();
  }, [triggerForgotPasswordEmail]);

  const login = async ({ email, password }) => {
    await submit(
      {
        email: email,
        password: password,
      },
      {
        method: "post",
        action: "/login?provider=cognito&action=signin",
      },
    );
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setResettingPassword(true);
      await verifyForgotPasswordCode(email, newPassword, verificationCode);
      await login({ email, password: newPassword });
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setResettingPassword(false);
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <form onSubmit={submitForm} data-testid="forgot-password-form">
      <Space direction="vertical" size="middle">
        <Text style={{ fontSize: "1.1rem" }}>
          A verification code has been sent to your email. Please enter it below along with your new password.
        </Text>

        <div>
          <Text strong>Verification Code *</Text>
          <Input
            required
            value={verificationCode}
            onChange={(event: any) => setVerificationCode(event.target.value)}
            type="text"
            data-testid="verification-code-input"
          />
        </div>

        <div>
          <Text strong>New Password *</Text>
          <Input
            required
            value={newPassword}
            onChange={(event: any) => setNewPassword(event.target.value)}
            type="password"
            data-testid="new-password-input"
          />
        </div>

        <Text type="danger">{error && <div>{error}</div>}</Text>
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          loading={resettingPassword}
          disabled={resettingPassword}
          className="w-full"
          data-testid="submit-button"
        >
          Confirm
        </Button>
        <Flex justify="center" align="center">
          <Button onClick={goToLogin} type="link" style={{ color: "black" }}>
            Back to Sign In
          </Button>
        </Flex>
      </Space>
    </form>
  );
};

export default ForgotPasswordForm;
