import { create } from "zustand";

interface AuthState {
  email: string;
  password: string;
  confirmPassword: string;
  emailVerified: boolean | null;
  screen: "login" | "forgotPassword" | "verifyEmail";
  error: string | null;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;
  setScreen: (screen: "login" | "forgotPassword" | "verifyEmail") => void;
  setEmailVerified: (emailVerified: boolean | null) => void;
  setError: (error: string | null) => void;
}

export const authState = create<AuthState>((set) => ({
  email: "",
  password: "",
  emailVerified: null,
  error: null,
  confirmPassword: "",
  screen: "login",
  setEmail: (email) => set({ email }),
  setPassword: (password) => set({ password }),
  setConfirmPassword: (confirmPassword) => set({ confirmPassword }),
  setScreen: (screen) => set({ screen }),
  setEmailVerified: (emailVerified) => set({ emailVerified }),
  setError: (error) => set({ error }),
}));
