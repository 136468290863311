import DynamicAuthForm from "@/components/Login/DynamicAuthForm";
import AuthBase from "@/components/Login/components/AuthBase";
import { authState } from "@/components/Login/state";
import antTheme from "@/theme/antTheme";
import { UserService } from "@condoit-apps/services/src/data/UserData.service";
import { Card, ConfigProvider } from "antd";
import { isNil } from "lodash-es";
import { useEffect } from "react";
// app/routes/login.tsx
import type { ActionFunctionArgs, LoaderFunctionArgs } from "react-router";
import { data, useActionData } from "react-router";
import { authenticator } from "../services/auth.server";
import { redirectSessionStorage } from "../services/session.server";
import { APPLE_PROVIDER, COGNITO_PROVIDER, GOOGLE_PROVIDER, READONLY_PROVIDER } from "../utils/constants";
import { ClientOnly } from "remix-utils/client-only";
// First we create our UI with the form doing a POST and the inputs with the
// names we are going to use in the strategy
export default function Screen() {
  const action = useActionData<typeof action>();
  const state = authState();
  console.log(action?.message);
  useEffect(() => {
    if (action?.action === "verifyEmail" && action.emailExists) {
      state.setEmailVerified(true);

      if (state.screen !== "login") {
        state.setScreen("login");
      }
    } else if (action?.action === "verifyEmail" && !action.emailExists) {
      state.setEmailVerified(false);
      if (state.screen !== "login") {
        state.setScreen("login");
      }
    } else if (action?.error) {
      state.setError(action.error);
    }
  }, [action]);

  const items = [
    { value: "a", title: "First Item", text: "Some value 1..." },
    { value: "b", title: "Second Item", text: "Some value 2..." },
    { value: "c", title: "Third Item", text: "Some value 3..." },
  ];

  return (
    <>
      <ClientOnly>
        {() => (
          <ConfigProvider theme={antTheme}>
            <AuthBase>
              <Card style={{ width: "600px", border: "none" }}>
                <div className="w-full">
                  <DynamicAuthForm />
                </div>
              </Card>
            </AuthBase>
          </ConfigProvider>
        )}
      </ClientOnly>
    </>
  );
}

// Second, we need to export an action function, here we will use the
// `authenticator.authenticate method`
export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const provider = url.searchParams.get("provider");
  const verifyEmail = url.searchParams.get("verifyEmail");
  const action = url.searchParams.get("action");
  const session = await redirectSessionStorage.getSession(request.headers.get("Cookie"));
  const redirect = session.get("redirect") || "/projects";
  try {
    if (action === "signin" && provider === "cognito") {
      // we call the method with the name of the strategy we want to use and the
      // request object, optionally we pass an object with the URLs we want the user
      // to be redirected to after a success or a failure
      return await authenticator.authenticate(COGNITO_PROVIDER, request, {
        successRedirect: redirect,
      });
      // biome-ignore lint/style/noUselessElse: bad rule. this is an else if. it's not useless.
    } else if (action === "signin" && provider === "readonly") {
      return await authenticator.authenticate(READONLY_PROVIDER, request, {
        successRedirect: redirect,
      });
      // biome-ignore lint/style/noUselessElse: bad rule. this is an else if. it's not useless.
    } else if (action === "signin" && provider === "apple") {
      return await authenticator.authenticate(APPLE_PROVIDER, request, {
        successRedirect: redirect,
      });
    }
    // biome-ignore lint/style/noUselessElse: bad rule. this is an else if. it's not useless.
    else if (action === "signin" && provider === "google") {
      return await authenticator.authenticate(GOOGLE_PROVIDER, request, {
        successRedirect: redirect,
      });
    }
  } catch (error) {
    if (error instanceof Response && error.status > 400) {
      //This is an actual error.
      const message = (await error.json()) as { message: string };

      return { error: message.message };
    }

    //So to stop loaders loading, remix-auth "throws" an error. So this is actually the success.
    //If you breakpoint here you can see the status of this is 302 which is the successRedirect from above. I know this is janky.
    return error;
  }

  if (action === "verifyEmail") {
    const userService = new UserService();
    const cognitoUser = await userService.getCognitoUser(verifyEmail);

    return {
      action: "verifyEmail",
      emailExists: !isNil(cognitoUser),
    };
  } else if (action === "signup") {
    const userService = new UserService();
    //signup the user
    return {
      action: "signup",
      emailExists: !isNil(cognitoUser),
    };
  }
}

// Finally, we can export a loader function where we check if the user is
// authenticated with `authenticator.isAuthenticated` and redirect to the
// dashboard if it is or return null if it's not
export async function loader({ request }: LoaderFunctionArgs) {
  // If the user is already authenticated redirect to /dashboard directly
  await authenticator.isAuthenticated(request, {
    successRedirect: "/home",
  });

  const url = new URL(request.url);
  const redirect = url.searchParams.get("redirect") || "/projects";

  const session = await redirectSessionStorage.getSession(request.headers.get("Cookie"));
  let cookie = "";
  if (redirect) {
    session.set("redirect", redirect);
    cookie = await redirectSessionStorage.commitSession(session);
  } else {
    cookie = await redirectSessionStorage.destroySession(session);
  }

  return data(
    {},
    {
      headers: {
        "Set-Cookie": cookie,
      },
    },
  );
}
