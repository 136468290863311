import { verifyEmailVerificationCode } from "@/apiCalls/user/usersCRUD";
import { Button, Input, Space, Typography } from "antd";
import { useState } from "react";
import { useSubmit } from "react-router";
import { authState } from "../../state";

const { Text } = Typography;

const VerifyEmailForm = ({}) => {
  const submit = useSubmit();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [reauthorizationRequired, setReauthorizationRequired] = useState(false);

  const email = authState((state) => state.email);
  const password = authState((state) => state.password);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await verifyEmailVerificationCode(email, code);

      if (password) {
        await login({ email, password });
      } else {
        setReauthorizationRequired(true);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const login = async ({ email, password }) => {
    await submit(
      {
        email: email,
        password: password,
      },
      {
        method: "post",
        action: "/login?provider=cognito&action=signin",
      },
    );
  };

  const handleSignInError = async (error: string) => {
    if (error.includes("Failed to sign in with existing password")) {
      setReauthorizationRequired(true);
    } else if (error.includes("Incorrect username or password")) {
      setReauthorizationRequired(true);
    } else {
      setError(error);
    }
  };

  if (reauthorizationRequired) {
    return (
      <>
        <Text style={{ fontSize: "1.1rem" }}>Thank you for verifying your email address!</Text>
        <br />
        <br />
        <Text style={{ fontSize: "1.1rem" }}>You may now sign in to your Condoit account.</Text>
        <br />
        <br />
        <Button
          onClick={() => authState.getState().setScreen("login")}
          type="primary"
          size="large"
          htmlType="submit"
          className="w-full"
        >
          Back to sign in
        </Button>
      </>
    );
  }

  return (
    <form onSubmit={submitForm} data-testid="verify-email-form">
      <Space direction="vertical" size="middle" className="w-full">
        <div style={{ marginBottom: 0, fontSize: "1.1rem" }}>
          A code has been sent to <b>{email}</b>.
        </div>
        <div style={{ fontSize: "1.1rem" }}>Please verify your email address by entering the code below.</div>
      </Space>

      <section className="flex flex-col w-full mt-2">
        <Input
          size="large"
          autoFocus
          placeholder="Enter verification code"
          disabled={loading}
          style={{ paddingRight: 40, marginBottom: 20, marginTop: 20 }}
          onChange={(event) => setCode(event.target.value)}
          value={code}
        />
        {error && <Text type="danger">{error}</Text>}

        <div className="pt-3 pb-3 w-full">
          <Button loading={loading} type="primary" size="large" htmlType="submit" className="w-full">
            Confirm
          </Button>
        </div>
        <Text
          onClick={() => authState.setState({ screen: "login" })}
          style={{ cursor: "pointer", textAlign: "center" }}
        >
          Back to sign in
        </Text>
      </section>
    </form>
  );
};

export default VerifyEmailForm;
