import { Box, HTMLChakraProps, Image, Stack } from "@chakra-ui/react";

export const LogoWithText = (props: HTMLChakraProps<"svg">) => (
  <Stack width={"200px"}>
    <Box>
      <Image
        width={200}
        height={100}
        objectFit="contain"
        src="/images/Condoit_Logo_Reverse.png"
        alt="Condoit_Logo_Reverse"
      />
    </Box>
  </Stack>
);
